<template>
  <div class="bg-white-900 rounded px-5 pt-6 pb-4 relative">
    <shortlist-header title="Rename shortlist" />
    <validation-observer tag="form" ref="form" @submit.prevent="handleSubmit">
      <div class="mt-9 mb-8">
        <utils-input
          validationRules="required|max:20"
          inputName="Name for shortlist"
          inputType="text"
          :isSubmitted="isSubmitted"
          v-model="data.name"
        />
      </div>
      <div>
        <base-button colorScheme="orange" class="ml-auto py-3 max-w-164 w-full">
          <span class="font-normal text-sm"> Save </span>
        </base-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import UtilsInput from "@/components/utils/UtilsInput.vue";
import BaseButton from "@/components/utils/Button.vue";
import ShortlistModalHeader from "@/components/utils/ShortlistModalHeader.vue";
import { RENAME_SHORTLIST } from "@/store/actions.type";

export default {
  name: "RenameShortlist",
  components: {
    ValidationObserver,
    "utils-input": UtilsInput,
    "base-button": BaseButton,
    "shortlist-header": ShortlistModalHeader,
  },
  props: ["params"],
  data() {
    return {
      isSubmitted: false,
      data: {
        name: this.params.oldName,
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        await this.$store.dispatch(RENAME_SHORTLIST, {
          slug: this.params.id,
          params: this.data,
        });

        this.$modal.hide("shortlist-modal");
      });
    },
  },
};
</script>
