<template>
  <div class="flex justify-between items-center">
    <h3 class="text-black-900 font-medium text-2xl mr-2">
      {{ title }}
    </h3>
    <button @click="$modal.hide('shortlist-modal')">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8574 19.8569L12.0002 11.9997M12.0002 11.9997L19.8574 4.14258M12.0002 11.9997L4.14307 19.8569M12.0002 11.9997L4.14307 4.14258"
          stroke="#212022"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "ShortlistModalHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
